import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
// import Img from 'gatsby-image';
import { Link } from 'gatsby';
const Hero = ({ size, title, subtitle, image, signup, cta, link }) => (
	<section className={`hero is-${size}`}>
		<div className="hero-body">
			<div className="container">
				<div className="columns is-vcentered">
					<div className="column is-half">
						<div className="content has-text-left">
							<h1 className="title is-size-1 has-text-weight-bold">{title}</h1>
							<h2 className="subtitle">{subtitle}</h2>
							<br />
							{signup && (
								<div className="is-hidden-mobile">
									<div className="field is-grouped">
										<p className="control">
											<a
												href="https://dashboard.unmerchant.com/"
												className="button is-primary is-large"
											>
												<strong>Start making money</strong>
											</a>
										</p>
										<p className="control">
											<a
												href="https://calendly.com/finhub/demo"
												className="button is-text is-large"
											>
												Schedule a demo
											</a>
										</p>
									</div>
								</div>
							)}
							{cta && (
								<Link className="button is-primary is-medium" to={link}>
									<strong>{cta} →</strong>
								</Link>
							)}
						</div>
					</div>

					{image && (
						<div className="column">
							<figure className="image is-400x400">
								<PreviewCompatibleImage imageInfo={image} />
							</figure>
						</div>
					)}
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
