import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Columns } from '../components/bulma';

export default function BlogRoll() {
	const data = useStaticQuery(BLOG_ROLL_QUERY);
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Columns multiline>
			{posts &&
				posts.map(({ node: post }) => (
					<div className="is-parent column is-6" key={post.id}>
						<article className={`blog-list-item tile is-child box notification }`}>
							<header>
								{post.frontmatter.featuredimage ? (
									<div className="featured-thumbnail">
										<PreviewCompatibleImage
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.title}`
											}}
										/>
									</div>
								) : null}
								<p className="post-meta">
									<Link className="title has-text-primary is-size-4" to={post.fields.slug}>
										{post.frontmatter.title}
									</Link>
									<span> &bull; </span>
									<span className="subtitle is-size-5 is-block">{post.frontmatter.date}</span>
								</p>
							</header>
							<p>
								{post.excerpt}
								<br />
								<br />
								<Link className="button" to={post.fields.slug}>
									Keep Reading →
								</Link>
							</p>
						</article>
					</div>
				))}
		</Columns>
	);
}

BlogRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

const BLOG_ROLL_QUERY = graphql`
	query BlogRollQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 400)
					id
					fields {
						slug
					}
					frontmatter {
						title
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	}
`;
